.primary-button {
    background-color: #1890ff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .secondary-button {
    background-color: #f0f0f0;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .card-column {
    flex: 1 1 calc(33.333% - 16px);
    max-width: calc(33.333% - 16px);
    min-width: 260px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  input, select {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .buttons button:nth-child(1) {
    margin-right: 20px;
  }

  @media (max-width: 1107px) {
    .cards-container {
        justify-content: center;
      }
  }

  @media (max-width: 563px) {
    .title-and-buttons {
      flex-flow: column;
      .title {
        margin-bottom: 20px;
      }
      .buttons {
        display: flex;
        width: 100%;
        justify-content: space-around;
        button {
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 388px) {
    .buttons {
      flex-flow: column;
      button:nth-child(1) {
        margin-bottom: 10px;
      }
    }
    .cards h2 {
      font-weight: 600;
      font-size: 17px;
      margin-bottom: 15px;
      width: 250px;
    }
  }
  