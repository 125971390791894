.loading-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .loading-text {
    margin-top: 16px;
    font-size: 16px;
    color: #001529; /* Color similar al de Ant Design */
  }
  