body {
  margin: 0;
  font-family: 'system-ui';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* PAGES */

/* User Profile */

.user-profile {
  margin: 20px;
}

.user-profile .title {
  display: flex;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 300;
}

/* MARGINS */

.margin-top-small {
  margin-top: 8px;
}

.margin-top-medium {
  margin-top: 16px;
}

.margin-top-large {
  margin-top: 24px;
}

.margin-bottom-small {
  margin-bottom: 8px;
}

.margin-bottom-medium {
  margin-bottom: 16px;
}

.margin-bottom-large {
  margin-bottom: 24px;
}

.margin-left-small {
  margin-left: 8px;
}

.margin-left-medium {
  margin-left: 16px;
}

.margin-left-large {
  margin-left: 24px;
}

.margin-right-small {
  margin-right: 8px;
}

.margin-right-medium {
  margin-right: 16px;
}

.margin-right-large {
  margin-right: 24px;
}

/* GRID */

.display-flex {
  display: flex;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}

/* WIDTH */

.w-200 {
  width: 350px;
}

/* FONTS */

.label-small {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
}

.label {
  font-size: 13px;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout-background {
  background: #fff;
  display: none;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

/* DASHBOARD*/

.sidebar-tags {
  position: absolute;
  bottom: 16px;
  width: 100%;
  padding: 0 16px;
}

.sidebar-tag {
  display: block;
  text-align: center;
  width: 100%;
  padding: 8px 0;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent !important;
}

.sidebar-tag:hover {
  opacity: 0.8;
}

/* LOADING */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

/* Disabled Tag */
.disabled-tag {
  background-color: #d9d9d96f !important;
  color: #c8c7c7 !important;
  cursor: not-allowed !important;
}

@media (max-width: 768px) {
  .site-layout-background {
    display: none;
  }
  .ant-layout-sider {
    display: none !important;
  }

  .ant-btn-link {
    color: #80b4ff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    min-width: 85px;
    padding: 30px 0;
    font-size: 13px;
  }

  .ant-btn-link .ant-btn-icon {
    margin: 0 !important;
    font-size: 25px;
    padding-bottom: 2px;
  }

  .ant-btn-link a {
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    text-decoration: none;
    color: inherit;
  }

  .logout span:nth-of-type(2) {
    font-size: 13px;
  }

  /* NAVBAR IN MOBILE */

  /* Navbar */
  .mobile-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000c17;
    border-top: 1px solid #ccc;
    z-index: 1000;
  }

  /* Button Navbar */
  .add-expense-button-mobile {
    position: relative;
    min-width: 50px;
    height: 20px;
    text-align: center;
  }

  .add-expense-button-mobile button {
    background-color: #005fb8;
    border: none;
    box-shadow: 0px 0px 0px 7px rgb(245 245 245);
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
  }

  /* NOTIFICATIONs */

  .ant-notification-notice {
    max-width: 90%;
    font-size: 14px; /* Ajusta el tamaño de la fuente según sea necesario */
  }
}

@media (min-width: 769px) {
  .mobile-nav,
  .add-expense-button-mobile {
    display: none;
  }
}
