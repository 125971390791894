/* Auth.css */
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 46px);
}
  
  .auth-card {
    width: 100%;
    max-width: 400px;
    padding: 24px;
    text-align: center;
    background: rgba(0, 0, 0, 0)
  }
  
  .auth-avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    border: 1px solid #ffffff00;
    border-radius: 100%;
    padding: 25px;
    box-shadow: 0px 5px 11px 5px #00000042;
  }
  
  .auth-title {
    margin-bottom: 24px !important;
    color: white !important;
  }
  
  .auth-form {
    width: 100%;
  }
  
  .auth-button {
    width: 100%;
    background: #1677ff;
    border: none;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .auth-button:hover {
    background: #00a6e0;
  }
  
  .auth-remember-me {
    color: white;
  }
  
  .auth-links {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .auth-links a {
    color: white;
  }

  .auth-container :where(.css-dev-only-do-not-override-j9bb5n).ant-input-affix-wrapper {
    padding: 10px;
  }
  
/* Ajuste específico para dispositivos móviles */
@media (max-width: 768px) {
    .auth-container {
        height: calc(100% - 46px);
    }
    .ant-notification-notice {
        max-width: 90%;
        font-size: 14px; /* Ajusta el tamaño de la fuente según sea necesario */
    }
}