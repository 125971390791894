.dashboard-title {
  font-size: 24px;
  text-align: left;
  margin-bottom: 24px;
}

.dashboard-chart {
    margin-top: 70px;
    margin-bottom: 70px;
}
  
@media (max-width: 768px) {
  .dashboard-title {
    font-size: 20px;
  }
}

.equal-height-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  box-shadow: 2px 2px 10px 2px rgb(233, 233, 233);
  border: 1px solid rgb(126, 126, 126);
}

.ant-card {
  border: 1px solid transparent !important;
}

.ant-card-body {
  padding: 0 !important;
  margin: 0 !important;
}

.loading-cards .equal-height-card {
  height: auto;
}

.loading-cards .equal-height-card h2 {
  font-size: 15px
}

@media (max-width: 768px) {
  .equal-height-card {
    text-align: left;
  }
  .expenses-counters {
      margin-bottom: 16px !important;
  }
}

/* Ajuste específico para dispositivos móviles */
@media (max-width: 768px) {
  .ant-notification-notice {
  max-width: 90%;
  font-size: 14px; /* Ajusta el tamaño de la fuente según sea necesario */
  }
}

/* TARGET CARDS */

.targets-cards .equal-height-card h2 {
  font-size: 15px;
  margin: 0;
}